import React from 'react';
import Scene3D from './components/Scene3D';
import PopupInfo from './components/PopupInfo';
import ClickableIcon from './components/ClickableIcon';
import ProductScene3D from './components/product/ProductScene3D';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainComponent from './components/MainComponent';
import ProductMainComponent from './components/ProductMainComponent';

const App: React.FC = () => {
  function handleIconClick(): void {
    throw new Error('Function not implemented.');
  }

  return (
    <Router>
    <Routes>
      <Route path="/" element={<MainComponent />} />
      <Route path="/project/adaptproject/komoda" element={<ProductMainComponent />} />
     
    </Routes>
  </Router>
  );
};

export default App;