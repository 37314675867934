// src/components/Scene3D.tsx
import React, { useEffect, useRef, useState } from 'react';
import { Color3, CubeTexture, Engine, MeshBuilder, PBRMaterial, Scene, StandardMaterial, } from '@babylonjs/core';
import '@babylonjs/loaders/glTF';
import {
  ArcRotateCamera,
  HemisphericLight,
  Vector3,
} from '@babylonjs/core';

import GenericModel from './GenericModel';
import CustomLoadingScreen from './CustomLoadingScreen';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../state/store';
import { setElectricityTransparency, setFloorTransparency, setFurnitureTransparency, setHeatTransparency, setInitialTriggerTransparency, setInstallationTransparency, setWaterTransparency, toogleDirectionalModelValue } from '../state/transparency/floorsTransparencySlice';
import '../App.css';
import DirectionalModel from './DirectionalModel';


const Scene3D: React.FC = () => {
  const dispatch = useDispatch();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [scene, setScene] = useState<Scene | null>(null);
  const [camera, setCamera] = useState<ArcRotateCamera | null>(null);


  const floorsTransparency= useSelector((state: RootState) => state.floorsTransparency.values);

  const furnitureVisibility = useSelector((state: RootState) => state.floorsTransparency.furnitureValues);
  const installationTransparency = useSelector((state: RootState) => state.floorsTransparency.installationValues);
  const heatTransparency = useSelector((state: RootState) => state.floorsTransparency.heatValues);
  const electricityTransparency = useSelector((state: RootState) => state.floorsTransparency.electricityValues);
  const waterTransparency = useSelector((state: RootState) => state.floorsTransparency.waterValues);

  const activeModelPopup = useSelector((state: RootState) => state.floorsTransparency.activeModelPopup);



  const toogleDirectionalModel = (key:string) => {
    dispatch(toogleDirectionalModelValue(key)); 
  };

  const apartmentId = "Apartment-Demo";
  const [apartmentData, setApartmentData] = useState<{
    description: string;

  } | null>(null);

  const [infoModelData, setInfoModelData] = useState<{
    description: string;

  } | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://animodapi.azurewebsites.net/Model/GetModel?id=${activeModelPopup}`);
        const data = await response.json();
        setInfoModelData({
          description: data.description
         
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    
  }, [activeModelPopup]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://animodapi.azurewebsites.net/Apartment/GetApartment?id=${apartmentId}`);
        const data = await response.json();
        setApartmentData({
          description: data.description
         
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    
  }, []);

  // Use useEffect to programmatically click the button on component mount with a delay
  useEffect(() => {
    const timer = setTimeout(() => {
        setTransparencyForFirstFloor();
        //setTransparencyForSecondFloor();

        setFurnitureVisibilityForFirstFloor();
        setFurnitureVisibilityForSecondFloor();

        setInstallationVisibilityForFirstFloor();
        setInstallationVisibilityForSecondFloor();

        setInitialTrigger();

        toogleDirectionalModel("f11");
        toogleDirectionalModel("f12");
        toogleDirectionalModel("f13");
        toogleDirectionalModel("f14");
        toogleDirectionalModel("f15");


        toogleDirectionalModel("f21");
        toogleDirectionalModel("f22");

    }, 2500); // 1 second delay

    // Cleanup the timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, []);


  
  const setInitialTrigger = () => {
    dispatch(setInitialTriggerTransparency({ value: true})); 
  };


  const setTransparencyForFirstFloor = () => {
    dispatch(setFloorTransparency({ floor: 1, value: floorsTransparency[1] === 1 ? 0 : 1 })); // First floor (index 2)
  };

  const setTransparencyForSecondFloor = () => {
    dispatch(setFloorTransparency({ floor: 2, value: floorsTransparency[2] === 1 ? 0 : 1 })); // Second floor (index 2)
  };


  //Furniture
  const setFurnitureVisibilityForFirstFloor = () => {
    dispatch(setFurnitureTransparency({ floor: 1, value: furnitureVisibility[1] === 1 ? 0 : 1 })); // First floor (index 2)
  };

  const setFurnitureVisibilityForSecondFloor = () => {
    dispatch(setFurnitureTransparency({ floor: 2, value: furnitureVisibility[2] === 1 ? 0 : 1 })); // Second floor (index 2)
  };

  //end Furniture

  //Installation
  const setInstallationVisibilityForFirstFloor = () => {
    dispatch(setInstallationTransparency({ floor: 1, value: installationTransparency[1] === 1 ? 0.5 : 1 })); // First floor (index 2)
  };

  const setInstallationVisibilityForSecondFloor = () => {
    dispatch(setInstallationTransparency({ floor: 2, value: installationTransparency[2] === 1 ? 0.5 : 1 })); // Second floor (index 2)
  };
  //END installation


  //Heat
  const setHeatVisibilityForFirstFloor = () => {
    dispatch(setHeatTransparency({ floor: 1, value: heatTransparency[1] === 1 ? 0 : 1 })); // First floor (index 2)
  };

  const setHeatVisibilityForSecondFloor = () => {
    dispatch(setHeatTransparency({ floor: 2, value: heatTransparency[2] === 1 ? 0 : 1 })); // Second floor (index 2)
  };
  //END Heat


  //Electricity
  const setElectricityVisibilityForFirstFloor = () => {
    dispatch(setElectricityTransparency({ floor: 1, value: electricityTransparency[1] === 1 ? 0 : 1 })); // First floor (index 2)
  };

  const setElectricityVisibilityForSecondFloor = () => {
    dispatch(setElectricityTransparency({ floor: 2, value: electricityTransparency[2] === 1 ? 0 : 1 })); // Second floor (index 2)
  };
  //END Electricity


  //Water
  const setWaterVisibilityForFirstFloor = () => {
    dispatch(setWaterTransparency({ floor: 1, value: waterTransparency[1] === 1 ? 0 : 1 })); // First floor (index 2)
  };

  const setWaterVisibilityForSecondFloor = () => {
    dispatch(setWaterTransparency({ floor: 2, value: waterTransparency[2] === 1 ? 0 : 1 })); // Second floor (index 2)
  };
  //END Water


  useEffect(() => {
    if (!canvasRef.current) return;

    const engine = new Engine(canvasRef.current, true);

    const scene = new Scene(engine);
    setScene(scene);

    const customLoadingScreen = new CustomLoadingScreen(scene, 'Loading...');
    engine.loadingScreen = customLoadingScreen;

    // Display the loading UI
    engine.displayLoadingUI();

    // Wait for 1.5 seconds and then hide the loading UI
    const timer = setTimeout(() => {
      engine.hideLoadingUI();
    }, 2350); // 1.5 seconds delay

    // Load HDRI and set it as the environment texture

    async function loadCubeTextures(scene: Scene): Promise<void> {
      // Helper function to load textures
      const loadTexture = async (url: string): Promise<CubeTexture> => {
          try {
              const texture = CubeTexture.CreateFromPrefilteredData(url, scene);
              await texture.onLoadObservable.addOnce(() => {
                  console.log(`${url} texture loaded.`);
              });
              return texture;
          } catch (error) {
              console.error(`Error loading texture from ${url}:`, error);
              throw error; // re-throw the error to be caught by the calling code
          }
      };
  
      try {
          // Load both textures asynchronously
          const [hdrTexture, pozadinaTexture] = await Promise.all([
              loadTexture("https://animodstorage.blob.core.windows.net/hdr/scene.env"),
              loadTexture("https://animodstorage.blob.core.windows.net/hdr/pozadina.env")
          ]);
  
          // Create skybox
          const skybox = MeshBuilder.CreateBox("skyBox", { size: 1000.0 }, scene);
          const skyboxMaterial = new StandardMaterial("skyBox", scene);
          skyboxMaterial.backFaceCulling = false;
          skyboxMaterial.reflectionTexture = pozadinaTexture.clone();
          skyboxMaterial.reflectionTexture.coordinatesMode = 5;
          skyboxMaterial.diffuseColor = new Color3(0, 0, 0);
          skyboxMaterial.specularColor = new Color3(0, 0, 0);
          skybox.material = skyboxMaterial;
  
          // Create a reflective cube
          const cube = MeshBuilder.CreateBox('cube', { size: 1000.0 }, scene);
          const cubeMaterial = new PBRMaterial('cube', scene);
          cubeMaterial.reflectionTexture = hdrTexture.clone();
          cubeMaterial.reflectionTexture.coordinatesMode = 3;
          cubeMaterial.cameraExposure = 0.66;
          cubeMaterial.cameraContrast = 1.66;
          cubeMaterial.microSurface = 0.96;
          cube.material = cubeMaterial;
  
          // Set the position of the cube
          cube.position = new Vector3(0, 1, 0); 
  
          // Set the environment texture
          scene.environmentTexture = hdrTexture;
        } catch (error) {
            console.error("Error loading textures:", error);
        }
    }
  
    // Call the loadCubeTextures function to load and apply the textures
    const handleHdr = async () => {
      await loadCubeTextures(scene);
    }

    handleHdr();
        
    const camera = new ArcRotateCamera(
      'camera1',
      Math.PI / 1.3,  // More from the side
      Math.PI / 4,  // Higher angle
      12.8,           // Distance from the target
      Vector3.Zero(),
      scene
    );

    camera.useAutoRotationBehavior = true;
    camera.attachControl(canvasRef.current, true);

    // Adjust scroll sensitivity
    //camera.wheelDeltaPercentage = 0.01; // Smaller values mean slower zoom
    camera.wheelPrecision = 150; // Larger values mean slower zoom
    camera.noRotationConstraint = true;

    scene.collisionsEnabled = true;
    setCamera(camera);

    const light = new HemisphericLight('light1', new Vector3(0, 1, 0), scene);

    engine.runRenderLoop(() => {
      scene.render();
    });

    const handleResize = () => {
      engine.resize();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      engine.dispose();
    };
  }, []);

  let eyeClosed = "img/eye-closed.png";
  let eyeOpen = "img/eye-open.png";
  let iconElectricity = "img/icon_electricity.png";
  let iconWater = "img/icon_water.png"
  let iconHeat = "img/icon_heat.png"
  let iconFurniture = "img/icon_furniture.png"
  let firstFloor = "img/icon_first.png";
  let secondFloor = "img/icon_second.png";

  const [expandedItems, setExpandedItems] = useState<{ [key: string]: boolean }>({
    'Floor 1': true,  // Item 1 is expanded by default
    'Floor 2': false, // Item 2 is collapsed by default
  });

  // Initialize checked subitems with desired defaults
  const [checkedSubItems, setCheckedSubItems] = useState<{ [key: string]: boolean }>({
    'Floor 1-Furniture': true,  // Subitem 1.1 under Item 1 is checked by default
    'Floor 1-Electricity': false, // Subitem 1.2 under Item 1 is unchecked by default
    'Floor 1-Water': false,  // Subitem 2.1 under Item 2 is checked by default
    'Floor 1-Heat': false, // Subitem 2.2 under Item 2 is unchecked by default
    'Floor 2-Furniture': true,  // Subitem 1.1 under Item 1 is checked by default
    'Floor 2-Electricity': false, // Subitem 1.2 under Item 1 is unchecked by default
    'Floor 2-Water': false,  // Subitem 2.1 under Item 2 is checked by default
    'Floor 2-Heat': false, // Subitem 2.2 under Item 2 is unchecked by default
  });

  const items = [
    {
      name: 'Floor 1',
      icon: firstFloor,
      subItems: [
        { name: 'Furniture', icon: iconFurniture },
        { name: 'Electricity', icon: iconElectricity },
        { name: 'Water', icon: iconWater },
        { name: 'Heat', icon: iconHeat },
      ],
    },
    {
      name: 'Floor 2',
      icon: secondFloor,
      subItems: [
        { name: 'Furniture', icon: iconFurniture },
        { name: 'Electricity', icon: iconElectricity },
        { name: 'Water', icon: iconWater },
        { name: 'Heat', icon: iconHeat },
      ],
    },
  ];


  const handleItemClick = (itemName: string) => {
    setExpandedItems(prevExpandedItems => {
      const isExpanded = !prevExpandedItems[itemName];
  
      //console.log(`Main Item: ${itemName}, Expanded: ${isExpanded}`);
  
      // Schedule the state update after the render phase
      setTimeout(() => {
        if(itemName === "Floor 1"){
          setTransparencyForFirstFloor();
        } else if(itemName === "Floor 2"){
          setTransparencyForSecondFloor();
        }
      }, 0);
  
      return {
        ...prevExpandedItems,
        [itemName]: isExpanded,
      };
    });
  };

  const handleSubItemClick = (mainItemName: string, subItemName: string) => {
    setCheckedSubItems(prevCheckedSubItems => {
      const isChecked = !prevCheckedSubItems[`${mainItemName}-${subItemName}`];
  
      //console.log(`Main Item: ${mainItemName}, Subitem: ${subItemName}, Checked: ${isChecked}`);
  
      // Schedule the state update after the render phase
      setTimeout(() => {
        if(mainItemName === "Floor 1"){
          if(subItemName === "Furniture"){
            setFurnitureVisibilityForFirstFloor();
          } else if(subItemName === "Electricity"){
            setElectricityVisibilityForFirstFloor();
          } else if(subItemName === "Water"){
            setWaterVisibilityForFirstFloor();
          } else if(subItemName === "Heat"){
            setHeatVisibilityForFirstFloor();
          }
        } else if(mainItemName === "Floor 2"){
          if(subItemName === "Furniture"){
            setFurnitureVisibilityForSecondFloor();
          } else if(subItemName === "Electricity"){
            setElectricityVisibilityForSecondFloor();
          } else if(subItemName === "Water"){
            setWaterVisibilityForSecondFloor();
          } else if(subItemName === "Heat"){
            setHeatVisibilityForSecondFloor();
          }
        }
      }, 0);
  
      return {
        ...prevCheckedSubItems,
        [`${mainItemName}-${subItemName}`]: isChecked,
      };
    });
  };

  return (
    <>
      <canvas ref={canvasRef} />
      {scene && camera && (
        <>
          {/* First floor */}
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="00_Grid_scene.glb" floor = {1} hasFurniture ={false} hasInstallation = {false} hasHeat={false} hasElectricity ={false} hasWater= {false} isDirectional={false} targetedModel="" /> 

          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="01_AC.glb" floor = {1} hasFurniture ={false} hasInstallation = {false} hasHeat={false} hasElectricity ={false} hasWater= {false} isDirectional={false} targetedModel="" /> 
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="01_Electricity.glb" floor = {1} hasFurniture ={false} hasInstallation = {false} hasHeat={false} hasElectricity ={true} hasWater= {false} isDirectional={false} targetedModel="" /> 
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="01_Furniture.glb" floor = {1} hasFurniture ={true} hasInstallation = {false} hasHeat={false} hasElectricity ={false} hasWater= {false} isDirectional={false} targetedModel="" /> 
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="01_Heat.glb" floor = {1} hasFurniture ={false} hasInstallation = {false} hasHeat={true} hasElectricity ={false} hasWater= {false} isDirectional={false} targetedModel="" /> 
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="01_Walls.glb" floor = {1} hasFurniture ={false} hasInstallation = {true} hasHeat={false} hasElectricity ={false} hasWater= {false} isDirectional={false} targetedModel="" /> 
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="01_Water.glb" floor = {1} hasFurniture ={false} hasInstallation = {false} hasHeat={false} hasElectricity ={false} hasWater= {true} isDirectional={false} targetedModel="" /> 
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="01_Construction.glb" floor = {1} hasFurniture ={false} hasInstallation = {false} hasHeat={false} hasElectricity ={false} hasWater= {false} isDirectional={false} targetedModel="" /> 

          {/* Second floor */}
         <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="02_AC.glb" floor = {2} hasFurniture ={false} hasInstallation = {false} hasHeat={false} hasElectricity ={false} hasWater= {false}  isDirectional={false} targetedModel="" /> 
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="02_Water.glb" floor = {2} hasFurniture ={false} hasInstallation = {false} hasHeat={false}  hasElectricity ={false}  hasWater= {true} isDirectional={false} targetedModel="" /> 
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="02_Walls.glb" floor = {2} hasFurniture ={false} hasInstallation = {true} hasHeat={false}  hasElectricity ={false} hasWater= {false} isDirectional={false} targetedModel="" /> 
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="02_Heat.glb" floor = {2} hasFurniture ={false} hasInstallation = {false} hasHeat={true}  hasElectricity ={false}  hasWater= {false} isDirectional={false} targetedModel="" />  
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="02_Electricity.glb" floor = {2} hasFurniture ={false} hasInstallation = {false} hasHeat={false}  hasElectricity ={true}  hasWater= {false} isDirectional={false} targetedModel="" />  
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="02_Furniture.glb" floor = {2} hasFurniture ={true} hasInstallation = {false} hasHeat={false}  hasElectricity ={false}  hasWater= {false} isDirectional={false} targetedModel="" />      
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="02_Construction.glb" floor = {2} hasFurniture ={false} hasInstallation = {false} hasHeat={false}  hasElectricity ={false}  hasWater= {false} isDirectional={false} targetedModel="" />      

          {/* Directional models First Floor */}

          <DirectionalModel scene={scene} camera ={camera} canvas={canvasRef.current} floor = {1} targetedModel="cam_01_dot" directionalPositionMesh="cam_01_dot" directionalModelKey='f11' /> 
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="01_cam_01_Living_room.glb" floor = {1} hasFurniture ={false} hasInstallation = {false} hasHeat={false} hasElectricity ={false} hasWater= {false}  isDirectional={false} targetedModel="" /> 

          <DirectionalModel scene={scene} camera ={camera} canvas={canvasRef.current} floor = {1} targetedModel="cam_02_dot" directionalPositionMesh="cam_02_dot" directionalModelKey='f12' />
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="01_cam_02_Dining_room.glb" floor = {1} hasFurniture ={false} hasInstallation = {false} hasHeat={false} hasElectricity ={false} hasWater= {false}  isDirectional={false} targetedModel="" />  

          <DirectionalModel scene={scene} camera ={camera} canvas={canvasRef.current} floor = {1} targetedModel="cam_03_dot" directionalPositionMesh="cam_03_dot" directionalModelKey='f13' />
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="01_cam_03_Kitchen.glb" floor = {1} hasFurniture ={false} hasInstallation = {false} hasHeat={false} hasElectricity ={false} hasWater= {false}  isDirectional={false} targetedModel="" /> 

         <DirectionalModel scene={scene} camera ={camera} canvas={canvasRef.current} floor = {1} targetedModel="cam_04_dot" directionalPositionMesh="cam_04_dot" directionalModelKey='f14' />
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="01_cam_04_Entrance.glb" floor = {1} hasFurniture ={false} hasInstallation = {false} hasHeat={false} hasElectricity ={false} hasWater= {false}  isDirectional={false} targetedModel="" /> 

          <DirectionalModel scene={scene} camera ={camera} canvas={canvasRef.current} floor = {1} targetedModel="cam_05_dot" directionalPositionMesh="cam_05_dot" directionalModelKey='f15' />
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="01_cam_05_WC.glb" floor = {1} hasFurniture ={false} hasInstallation = {false} hasHeat={false} hasElectricity ={false} hasWater= {false}  isDirectional={false} targetedModel="" /> 

          {/* Directional models Second Floor */}
          <DirectionalModel scene={scene} camera ={camera} canvas={canvasRef.current} floor = {2} targetedModel="02_cam_01_dot" directionalPositionMesh="02_cam_01_dot" directionalModelKey='f21' />
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="02_cam_01_Sleeping_room.glb" floor = {2} hasFurniture ={false} hasInstallation = {false} hasHeat={false} hasElectricity ={false} hasWater= {false}  isDirectional={false} targetedModel="" /> 

          <DirectionalModel scene={scene} camera ={camera} canvas={canvasRef.current} floor = {2} targetedModel="02_cam_02_dot" directionalPositionMesh="02_cam_02_dot" directionalModelKey='f22' />
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="02_cam_02_WC.glb" floor = {2} hasFurniture ={false} hasInstallation = {false} hasHeat={false} hasElectricity ={false} hasWater= {false}  isDirectional={false} targetedModel="" /> 

        </>
      )}
       <div className="expandable-list">
      {items.map((item, index) => (
        <div key={index} className="item">
          <button
            className="item-button"
            onClick={() => handleItemClick(item.name)}
          >
            <img src={item.icon} alt="Item Icon" className="item-icon" />
            {item.name}
            <span className={`expand-icon ${expandedItems[item.name] ? 'expanded' : ''}`}>
              ▼
            </span>
          </button>
          {expandedItems[item.name] && (
            <div className="subitems">
              {item.subItems.map((subItem, subIndex) => (
                <div key={subIndex} className="subitem-container">
                  <a
                    className="subitem"
                    onClick={() => handleSubItemClick(item.name, subItem.name)}
                  >
                    <img src={subItem.icon} alt="Subitem Icon" className="subitem-icon" />
                    {subItem.name}
                  </a>
                  <img
                    src={checkedSubItems[`${item.name}-${subItem.name}`] ? eyeOpen : eyeClosed}
                    alt="Eye Icon"
                    className="eye-icon"
                    onClick={() => handleSubItemClick(item.name, subItem.name)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
    </>
  );
};

export default Scene3D;
