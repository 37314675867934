// src/components/GenericModel.tsx
import React, { useEffect, useRef, useState } from 'react';
import '@babylonjs/loaders/glTF';
import { SceneLoader, Scene, AbstractMesh, Color3, ArcRotateCamera } from '@babylonjs/core';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../state/store';

interface GenericModelProps {
  model:string;
  scene: Scene;
  camera:ArcRotateCamera ,
  canvas: HTMLCanvasElement | null
  floor:number;
  hasFurniture:boolean
  hasInstallation:boolean
  hasHeat:boolean,
  hasElectricity:boolean,
  hasWater:boolean
  isDirectional:boolean
  targetedModel:string
}

const GenericModel: React.FC<GenericModelProps> = (props) => {

  const floorsTransparency= useSelector((state: RootState) => state.floorsTransparency.values);

  const furnitureVisibility = useSelector((state: RootState) => state.floorsTransparency.furnitureValues);
  const installationTransparency = useSelector((state: RootState) => state.floorsTransparency.installationValues);
  const heatTransparency = useSelector((state: RootState) => state.floorsTransparency.heatValues);
  const electricityTransparency = useSelector((state: RootState) => state.floorsTransparency.electricityValues);
  const waterTransparency = useSelector((state: RootState) => state.floorsTransparency.waterValues);

  const meshesRef = useRef<AbstractMesh[]>([]);

  useEffect(() => {
    if (!props.scene) return;

    SceneLoader.ImportMesh("", "https://animodstorage.blob.core.windows.net/models3d/", props.model, props.scene, (meshes) => {
      meshesRef.current = meshes;

      meshes.forEach((mesh) => {
        //collision
        mesh.checkCollisions = true;
      
        if (mesh.material) {
          mesh.isVisible = false;
        }
      });
    });
  }, [props.scene, props.floor,props.model]);

  //Floors visibility
  useEffect(() => {
    meshesRef.current.forEach((mesh) => {
      if (mesh.material) {
        mesh.material.alphaMode = 2;
        mesh.material.transparencyMode = 2;

        //Strongest condition - disable floor visibility or hasFurniture and hasInstallation are false
        if(floorsTransparency[props.floor] === 0 || (!props.hasFurniture && !props.hasInstallation)){
          //mesh.material.alpha = floorsTransparency[props.floor];
          mesh.isVisible = floorsTransparency[props.floor] === 1 ? true : false;
        }
        //If model is Furniture
        else if(floorsTransparency[props.floor] === 1 && props.hasFurniture ){
          //mesh.material.alpha = furnitureVisibility[props.floor];
          mesh.isVisible = furnitureVisibility[props.floor] === 1 ? true : false;
        }
        //If model is Installation
        else if(floorsTransparency[props.floor] === 1 && props.hasInstallation ){
          mesh.isVisible = true;
          mesh.material.alpha = installationTransparency[props.floor];
        }

        if(floorsTransparency[props.floor] === 1 && (heatTransparency[props.floor] === 1 || electricityTransparency[props.floor] === 1 || waterTransparency[props.floor] === 1 ) && props.hasInstallation ){
          mesh.material.alpha = 0.3;
        }
      }
    });
  }, [floorsTransparency,heatTransparency,electricityTransparency,waterTransparency]);

  //Furniture visibility
  useEffect(() => {

    meshesRef.current.forEach((mesh) => {
      if (mesh.material) {
        let transparencyValue = floorsTransparency[props.floor];
        if(transparencyValue === 1 && props.hasFurniture){
          mesh.isVisible = furnitureVisibility[props.floor] === 1 ? true : false;
        } 
      }
    });
  }, [furnitureVisibility]);

  //Installation transparency
  useEffect(() => {
    meshesRef.current.forEach((mesh) => {
      if (mesh.material) {
        mesh.material.alphaMode = 2;
        mesh.material.transparencyMode = 2;

        let transparencyValue = floorsTransparency[props.floor];
        if(transparencyValue === 1 && props.hasInstallation){
          mesh.isVisible = true;
          mesh.material.alpha = installationTransparency[props.floor];
        } 
      }
    });
  }, [installationTransparency]);

  //Heat transparency
  useEffect(() => {
    meshesRef.current.forEach((mesh) => {
      if (mesh.material) {
        mesh.material.alphaMode = 2;
        mesh.material.transparencyMode = 2;

        let transparencyValue = floorsTransparency[props.floor];
        if(transparencyValue === 1 && props.hasHeat){
          mesh.isVisible = true;

          if(heatTransparency[props.floor] === 1){
              // Enable overlay rendering
              mesh.renderOverlay = true;
              // Set overlay color
              mesh.overlayColor = Color3.Red(); // Red color
          } else {
              mesh.renderOverlay = false;
          }
        } 
      }
    });
  }, [heatTransparency]);

  //Electricity transparency
  useEffect(() => {
    meshesRef.current.forEach((mesh) => {
      if (mesh.material) {
        mesh.material.alphaMode = 2;
        mesh.material.transparencyMode = 2;

        let transparencyValue = floorsTransparency[props.floor];
        if(transparencyValue === 1 && props.hasElectricity){
          mesh.isVisible = true;
          if(electricityTransparency[props.floor] === 1){
              // Enable overlay rendering
              mesh.renderOverlay = true;
              // Set overlay color
              mesh.overlayColor = Color3.Yellow(); // Red color
          } else {
              mesh.renderOverlay = false;
          }
        } 
      }
    });
  }, [electricityTransparency]);

  //Water transparency
  useEffect(() => {
    meshesRef.current.forEach((mesh) => {
      if (mesh.material) {
        mesh.material.alphaMode = 2;
        mesh.material.transparencyMode = 2;

        let transparencyValue = floorsTransparency[props.floor];
        if(transparencyValue === 1 && props.hasWater){
          mesh.isVisible = true;
          if(waterTransparency[props.floor] === 1){
            mesh.renderOverlay = true;
            // Set overlay color
            mesh.overlayColor = Color3.Blue(); // Blue color
          } else {
            mesh.renderOverlay = false;
          }
        } 
      }
    });
  }, [waterTransparency]);

  return null;
};

export default GenericModel;


