import { ILoadingScreen, Scene } from '@babylonjs/core';
import { AdvancedDynamicTexture, Rectangle, TextBlock, Control } from '@babylonjs/gui';

class CustomLoadingScreen implements ILoadingScreen {
  public loadingUIText: string;
  private scene: Scene;
  private loadingBar!: Rectangle;
  private loadingText!: TextBlock;
  private loadingUI!: AdvancedDynamicTexture;

  constructor(scene: Scene, loadingUIText: string) {
    this.loadingUIText = loadingUIText;
    this.scene = scene;
    this.createLoadingBar();
  }
  loadingUIBackgroundColor!: string;

  createLoadingBar() {
    // Create AdvancedDynamicTexture
    this.loadingUI = AdvancedDynamicTexture.CreateFullscreenUI("UI");

    // Create the loading bar
    this.loadingBar = new Rectangle("loadingBar");
    this.loadingBar.width = "50%";
    this.loadingBar.height = "10px";
    this.loadingBar.color = "white";
    this.loadingBar.thickness = 2;
    this.loadingBar.background = "green";
    this.loadingBar.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    this.loadingBar.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    this.loadingUI.addControl(this.loadingBar);

    // Create the loading text
    this.loadingText = new TextBlock();
    this.loadingText.text = this.loadingUIText;
    this.loadingText.color = "white";
    this.loadingText.fontSize = 24;
    this.loadingText.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    this.loadingText.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    this.loadingText.top = "50px";
    this.loadingUI.addControl(this.loadingText);

    // Initially hide the loading bar
    this.loadingBar.isVisible = false;
    this.loadingText.isVisible = false;
  }

  displayLoadingUI() {
    this.loadingBar.isVisible = true;
    this.loadingText.isVisible = true;
  }

  hideLoadingUI() {
    this.loadingBar.isVisible = false;
    this.loadingText.isVisible = false;
  }
}

export default CustomLoadingScreen;