import React, { useEffect, useState } from 'react';
import '../PopupInfo.css';

import Scene3D from './Scene3D';
import PopupInfo from './PopupInfo';
import ClickableIcon from './ClickableIcon';



const MainComponent: React.FC = () => {

    function handleIconClick(): void {
        throw new Error('Function not implemented.');
      }

    return (
        <div className="App">
    
          <div className="right">
            <Scene3D />
          </div>
          <PopupInfo 
            title="" 
            description=""
          />
          <ClickableIcon 
            iconSrc={'img/icon_back.png'} 
            altText="Clickable Icon" 
            onClick={handleIconClick} 
          />
        </div>
      );
};

export default MainComponent;