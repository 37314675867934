import React, { useEffect, useState } from 'react';
import '../PopupInfo.css';
import { RootState } from '../state/store';
import { useSelector } from 'react-redux';

interface PopupInfoProps {
  title: string;
  description: string;
}

const PopupInfo: React.FC<PopupInfoProps> = ({ title, description }) => {

  const activeModelPopup = useSelector((state: RootState) => state.floorsTransparency.activeModelPopup);

  const apartmentId = "Apartment-Demo";
  const [apartmentData, setApartmentData] = useState<{
    description: string;

  } | null>(null);

  const [infoModelData, setInfoModelData] = useState<{
    description: string;

  } | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://animodapi.azurewebsites.net/Model/GetModel?id=${activeModelPopup}`);
        const data = await response.json();
        setInfoModelData({
          description: data.description
         
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    
  }, [activeModelPopup]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://animodapi.azurewebsites.net/Apartment/GetApartment?id=${apartmentId}`);
        const data = await response.json();
        setApartmentData({
          description: data.description
         
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    
  }, []);


  return (
    <div className="popup-info">
      <h3 className="popup-info-title">{title}</h3>
      <p className="popup-info-description">
      {activeModelPopup !== "" && infoModelData !== null  ? (
          <div dangerouslySetInnerHTML={{ __html: infoModelData.description }} />
          
        ) : (
          apartmentData !== null ?
          <div dangerouslySetInnerHTML={{ __html: apartmentData.description }} />
            : ""
        )}

        {description}</p>
    </div>
  );
};

export default PopupInfo;